import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Page } from './pages/page';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const pages = [
         { id: 0, name: '', title : '', contents :  
    '<div class="fadein1">'+
     '<img  src="assets/images/ECMS-logo.jif">'+
    '<img  src="https://angular.io/assets/images/logos/angular/angular.svg" />'+
    '<img  src="assets/images/Android-logo.png">'+
   '<img  src="assets/images/Bootstrap-logo.png">'+
     '<img  src="assets/images/Bluemix-Logo.png">'+ 
      '<img  src="assets/images/Java-Logo.png">'+ 
     '<img  src="assets/images/JavascriptLogo.png">'+ 
     '<img  src="assets/images/CSS.png">'+ 
     '<img  src="assets/images/Microsoft-logo.jif">'+ 
     '<img  src="assets/images/NodeJS-Logo.png">'+ 
     '<img  src="assets/images/Unity3d-logo.png">'+ 
     '<img  src="assets/images/Watson-Logo.png">'+ 
      '</div>'
    }, 
      { id: 1, name: 'Application Development', title : 'Application Development', contents : 
            '<div class="center-text"><div class="expandableparent">Enterprise Level Application Development and Support<div class="expandable">I have worked alongside several organizations, help them meet there business needs and goals. I have had the opportunity to help both Start new projects, Integrate features into existing projects, and track and solve defects in existing code bases.</div></div>'+
            '<div class="expandableparent">Applications for improving business efficiency  and performance<div class="expandable">Every business and organization functions differently. Often times there are very specific tools needed to help slightly improve performance and enhance business  capabilities. I can help you build and maintain these custom tools and we can work together to deploy them to your enviroments.</div></div>'+
            '<div class="expandableparent">Tools for improving development time and streamlining development<div class="expandable">Some applications development  requires tedious class making and regular manual task that are the same if not similiar. Why not look into automation or tools to help reduce that time. I have developed many tools in the past to help generate code, test code, and generate test cases for developers. This can leave your developers more time to worry about the logical aspects of the application.</div></div>'+
            '<div class="expandableparent">Cognitive systems interacting with user data<div class="expandable">Don\'t let your organization get left behind in the new age of Cognitive apps. I have experience in developing cognitive apps that work with user input data and apis to deliver the clients needs.</div></div>'+
            '<div class="expandableparent">Dynamic Web applications and Web Sites<div class="expandable">Websites don\'t have to be very complex or convuluted to deliver dynamiic and exciting content. With the use of different libraries and DOM manipulation, I can help you get a simple dyamic and interactive website up and running in no time</div></div>'},
      { id: 2, name: 'Clients', title : 'Client', contents :
        '<div class="container"> <div [id]="content-slide" class="content-slider">   <div class="slider">   <div class="mask">  '+ 
        '<ul> <li id="first" class="firstanimation">  <a href="#"> <img src="assets/images/IBM-Logo.png" alt="IBM"/> </a> '+
        '<div class="tooltip"> <h1>IBM - IT Consulting and Application Development</h1> </div>  </li>'+ 
        '<li id="second" class="secondanimation"> <a href="#"> <img src="assets/images/CGI-Logo.png" alt="CGI"/> </a> '+
        '<div class="tooltip"> <h1>J2EE Application Support</h1> </div>     </li> '+
        '<li id="third" class="thirdanimation"> '+
        '<a href="#"> <img src="assets/images/DeutscheBank-Logo.png" alt="Deutsche Bank"/> </a> '+
        '<div class="tooltip"> <h1>Commodities and Trading Platform Development and Support</h1> </div> </li> '+
        '<li id="fourth" class="fourthanimation"> '+
        '<a href="#"> <img src="assets/images/HRM-Logo.png" alt="Halifax Regional Municipality"/> </a> '+
        '<div class="tooltip"> <h1>Web Development and Site Migration</h1> </div>    </li>'+
        '<li id="fifth" class="fifthanimation"> <a href="#"> <img src="assets/images/OPG-Logo.png" alt="Government Of Ontario"/> </a> '+
        '<div class="tooltip"> <h1>J2EE Application Support</h1> </div>     </li>    </ul>  </div>   '+
'            <div class="progress-bar"></div>   </div>     </div>'+
'<p class="content-client"> Over the past years I have worked alongside several companies and corporations to help further  their business needs. I have successfully helped develop and support applications for larger financial firms as well as several government entities. Each project has its own challenges to overcome and knowledge to be learned.</p></div> '  },
      { id: 4, name: 'Flavours', title : 'Languages', contents : 
      '<div class="center-text"><div class="expandableparent">Programming Languages<div class="expandable">'+
     '<div class="expandableparent1"> Java, JSP, J2EE<div class="expandable1">JDK 1.0, JDK 1.1,Java E 6+  </div></div>'+
      '<div class="expandableparent1"> HTML , CSS , XML<div class="expandable1">HTML5 , XML 1.0, CSS Level 1+ </div></div>'+
      '<div class="expandableparent1"> JavaScript, Typescript<div class="expandable1">ECMAScript 2017</div></div>'+
      '<div class="expandableparent1"> C# , C++<div class="expandable1">.NET, ASP.NET</div></div>'+
      '<div class="expandableparent1">Python, PHP<div class="expandable1">Python 2.7x+, PHP 5+</div></div>'+
      '</div></div>'+
      '<div class="center-text"><div class="expandableparent">Frameworks<div class="expandable">'+
      '<div class="expandableparent1">Maven, Gradle, Ant<div class="expandable1">Build tools to enable compiling and deployment of Web Applications, Services, and Mobile apps.</div></div>'+
      '<div class="expandableparent1">Hibernate, Spring<div class="expandable1">Enable your web application with seamless data binding, both to the UI and the Database. The Spring framework also allows for the quick creation of web services.</div></div>'+
      '<div class="expandableparent1">Node.js, jQuery, Bootstrap<div class="expandable1">Node.JS is quickly becoming popular for backend development. The dynamic ability of JavaScript allows for as much functionality as one would expect from JAVA enterprise.</div></div>'+
      '<div class="expandableparent1">Angular.io, Angular.js<div class="expandable1">Angular is a very powerful framework which when powered with Node.js, can be used to manipulate the front end and the backend. This whole site is designed dynamically using Node.js and Angular.io.</div></div>'+
      '<div class="expandableparent1">.Net, ASP.NET<div class="expandable1">.NET was one of the first frameworks I explored. The framework is ideal for Microsoft platform software. Windows forms can be utilized as well with ASP.NET to meet the client needs and develop native desktop applications.</div></div>'+
     '</div></div>'+
      '<div class="center-text"><div class="expandableparent">API\'s<div class="expandable">'+
        '<div class="expandableparent1">IBM<div class="expandable1">Bluemix, Watson Health Core, Watson </div></div>'+
        '<div class="expandableparent1">Map<div class="expandable1">Openlayers, Google Maps, HTML5 Geolocation</div></div></div>'+
        '</div></div>'+
        '<div class="center-text"><div class="expandableparent">Blockchain<div class="expandable"> '+
        '<div class="expandableparent1">Hyperledger<div class="expandable1">Hyperledger , Hyperledger Composer, Java and Node.js  Hyperledger Business Network deployment and App Development </div></div>'+
        '</div></div></div>'},
     
      { id: 5, name: 'About', title : 'About', contents : "<div class='center-text'><div class='about'><p>I am a highly dedicated IT Specialist. I have worked independently training my skills for over 20 years. I enjoy finding new challenges and seeing the results of my work in action.</p><p>I have worked in many different scenarios, on huge teams and individually. Each poses its own challenges to overcome. I am well-disciplined and able to meet deadlines as required."+
       ' I have had the pleasure of working in offices for major corporations alongside clients, as well as remotely  from my home office'+
      ' or the clients location.</p><p>My unique abilities and ongoing training make me the ideal addition to almost '+
       'any project. I am easy-going and pretty relaxed and would love to hear any opportunities you would like to explore'+
       ' together.</p></div></div>'},
     
        { id: 7, name: 'Products', title : 'Products', contents : "<div class='center-text'><div class='expandableparent'>Custom Java Library Development"+
        "<div class='expandable'>Libraries and Services are always needed. Over the years I have developed many Java libraries and Services for Web-Based Applications and Desktop Programs.<br>Class development can get to be a tedious task for big applications. I excel at developing and using tools to help streamline backend development.</div></div>"+
        "<div class='expandableparent'>Front end and Back end API interaction<div class='expandable'>Almost every successful web application has some sort of API integration. I have worked with a vast number of API\'s, and with the use of proper documentation can successfully integrate API's into the front end or back end of your Application</div></div>"+
        "<div class='expandableparent'>API Development<div class='expandable'>Many companies and enterprises use their own API's to leverage data for use by developers. I have experience in developing API's and RESTFUL services, designed for easy consumption.</div></div>"+
        "<div class='expandableparent'>Prototype Development<div class='expandable'>I love developing prototypes of Apps and Websites. Using a myriad of different tools allows me to quickly develop functional prototypes of your application. These are key for understanding the functionality and the seeing how your end product will look and perform</div></div>"+
        "<div class='expandableparent'>Individual and Business Website Development and Maintenace<div class='expandable'>Need to get your brand online? I have worked on developing many small and large scale websites for individuals and corporations. I can help you generate an online presence from scratch or from one of your favorite templates.</div></div>"+
        "<div class='expandableparent'>Website Migration<div class='expandable'>Website migration and update can be a tedious task. I can help automate some parts of updateing your website and importing your content to new templates. I can also provide maunal help as needed to help make sure the migration and updates get done on time and on budget</div></div>"+
        "<div class='expandableparent'>Custom JavaScript Libraries<div class='expandable'>Javascript can be leveraged by both front end developers as well as backend developers. I have experience in Angular and Node.js creating custom Javascript libraries for Web Applications</div></div></div>"}, 
            { id: 8, name: 'Tools', title : 'Tools', contents :
            "<div class='center-text'><div class='about'>"+
            "<div class='expandableparent'>Microsoft Suite and Development Tools<div class='expandable'>Windows, Visual Studios, Excel, Viso</div></div><div>"+
            "<div class='expandableparent'>Open Source<div class='expandable'>Eclipse, Lunix</div></div>"+
            "<div class='expandableparent'>Application Servers<div class='expandable'>Apache Server, Oracle Weblogic, IBM WAS</div></div>"+
            "<div class='expandableparent'>Source Control <div class='expandable'>Git, Github, Bitbucket, Tortoise, Jazz</div></div>"},
        { id: 9, name: 'Contact', title : 'Contact', contents : "<div class='center-text'>"+
        "Looking for some help on a new or existing project. I am always seeking new challenges and exciting adventures"+
        "<div class='expandableparent'>email<div class='expandable'><a href='mailto:aaronali@email.com?Subject=Hello' target='_top'>aaronali@email.com</a></div></div>"+
         "<div class='expandableparent'>linkden<div class='expandable'><a href='https://www.linkedin.com/in/aaron-ali-0a42558b/' target='blank'>Linkedin</a></div></div>"+
       "<div class='expandableparent'>Phone<div class='expandable'><a href='tel:+19027031590'>902 703 1590</a></div></div>"+
        "</div>"}
    ];

    return {pages};
  }

  // Overrides the genId method to ensure that a hero always has an id.
  // The method below returns the highest id + 1.
  genId(pages: Page[]): number {
    return  Math.max(...pages.map(page => page.id)) + 1;
  }
} 