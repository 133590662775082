import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Aaron Ali - IT Consulting and App Development';
}

 export function fadeContent(){
     if(document.getElementById('content').className.indexOf('hidden')>0){
         document.getElementById('content').className='content show';
     }else {
                document.getElementById('content').className = "content hidden"  ;
     }
}