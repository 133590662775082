import { Component, OnInit } from '@angular/core';
import {PageService} from  "./page.service";
import {Page} from  "./page";
@Component({
  selector: 'app-page',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
 pages:Page[]; 
 
  constructor(private pageService: PageService) { } 
  ngOnInit() {
    this.getPages();
  }

  getPages(): void {
    this.pageService.getPages()
    .subscribe(pages => this.pages = pages);
  }

}
 