import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { PagesComponent } from './pages/pages.component';
import { PageDetailComponent } from './page-detail/page-detail.component';

const routes: Routes = [
 // { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, 
  { path: 'page-detail/:id', component: PageDetailComponent }, 
  { path: 'pages', component: PagesComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

 