import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Page } from '../pages/page';
import  { PageService } from '../pages/page.service'; 


import { testFuncion } from '../../../projects/helperlib/src/public_api';
import { HelperlibService } from '../../../projects/helperlib/src/public_api';
import { setInnerHTML } from '../../../projects/helperlib/src/public_api';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.css']
})
export class PageDetailComponent implements OnInit {
  @Input() page: Page;


  constructor(
    private route: ActivatedRoute,
    private pageService: PageService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getPage();
  }

  getPage(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.pageService.getPage(id)
      .subscribe(page => this.page = page);
  }

  goBack(): void {
    this.location.back();
  }
 
}
 