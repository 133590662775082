import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MessageService } from '../message.service'; 
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Page } from './page';

 


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

   
@Injectable({ providedIn: 'root' })
export class PageService {

  private url = 'api/pages';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }

  /** GET heroes from the server */
  getPages (): Observable<Page[]> {
    return this.http.get<Page[]>(this.url)
      .pipe(
        tap(_ => this.log('fetched pages')),
        catchError(this.handleError<Page[]>('get{ages', []))
      );
  }

  /** GET hero by id. Return `undefined` when id not found */
  getHeroNo404<Data>(id: number): Observable<Page> {
    const url = `${this.url}/?id=${id}`;
    return this.http.get<Page[]>(url)
      .pipe(
        map(pages => pages[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} hero id=${id}`);
        }),
        catchError(this.handleError<Page>(`getHero id=${id}`))
      );
  }

  /** GET hero by id. Will 404 if id not found */
  getPage(id: number): Observable<Page> {
    const url = `${this.url}/${id}`;
    return this.http.get<Page>(url).pipe(
      tap(_ => this.log(`fetched page id=${id}`)),
      catchError(this.handleError<Page>(`getPage id=${id}`))
    );
  }

  /* GET heroes whose name contains search term */
  searchPage(term: string): Observable<Page[]> {
    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }
    return this.http.get<Page[]>(`${this.url}/?name=${term}`).pipe(
      tap(_ => this.log(`found pages matching "${term}"`)),
      catchError(this.handleError<Page[]>('searchPages', []))
    );
  }

 

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a PageService message with the PageService */
  private log(message: string) {
    this.messageService.add(`PageService: ${message}`);
  }
}  